/* You can add global styles to this file, and also import other style files */
@import "styles/material-theme";
@import "../../_base-shared/styles/base-shared-styles";


.table-striped tbody tr:nth-of-type(odd){
  background-color: rgb(80 80 80 / 11%) !important;
}

.cdk-overlay-container .dropdown-custom {
  margin-top: 23px;
}

.cdk-overlay-container .dropdown-custom-two {
  margin-top: 8px;
}



.cdk-overlay-pane .selectAllMenu{
  position: absolute !important;
  top: -41px;
  right: 115px;
}

.selectAllMenu {
  .mat-menu-item{
    font-size: 13px !important;
  }
}

.cdk-overlay-pane .sendContractMenu{
  position: absolute !important;
  top: -38px;
  left: 165px;
}

.cdk-overlay-pane .resignContractMenu{
  position: absolute !important;
  top: -38px;
  left: -145px;
}

.resignContractMenu {
  .mat-menu-item{
    font-size: 13px !important;
  }
}

.cdk-overlay-pane .eSignMenu{
  position: absolute !important;
  top: -36px;
  right: 244px;
}

.eSignMenu {
  .mat-menu-item{
    font-size: 13px !important;
  }
}


.cdk-overlay-pane .sendSepaLinkMenu{
  position: absolute !important;
  top: -37px;
  left: 171px;
}

.cdk-overlay-pane .sendMenu{
  position: absolute !important;
  top: 324px;
  left: -125px;
}

.notificationsMenu .mat-menu-content:not(:empty){
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.userMenu .mat-menu-content:not(:empty){
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
